/**
  * Start Common styles
 */
.animate {
  transition: transform 0.2s;
}

.animate:hover {
  transform: scale(1.1);
}
/**
  * Start Common styles
 */

/**
  * Dashboard-menu-bar
 */
.play-dashboard-level {
  background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/dashboard-bar/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 70px;

  .details-bar {
    background-repeat: no-repeat;
    background-size: 100%;
    max-width: 200px;
    font-family: gameria, serif;
    font-size: x-large;
    color: white;
    text-align: center;
    padding-top: 15px;
    padding-left: 15px;

    &.avatar {
      background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/dashboard-bar/avatar.png');
      width: 120px !important;
      height: 120px !important;
      border-radius: 50%;
    }

    &.score {
      background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/dashboard-bar/score.png');
      height: 60px;
      padding-top: 20px;
    }

    &.time {
      background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/dashboard-bar/time.png');
      height: 60px;
    }

    &.rewards {
      background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/dashboard-bar/reward_button.png');
      width: 75px !important;
      height: 75px;
    }
  }
}

/**
  * End dashboard-menu-bar
*/
/*------------------------------------------------------------*/
/**
  * Start level-game
 */
.p-overlaypanel {
  margin-top: 10px !important;
  position: absolute !important;
  border: solid 5px #e4cc74 !important;
  border-radius: 15% !important;
  background: rgb(50 214 249 / 0.9) !important;
  width: 33% !important;
}

.p-overlaypanel .p-overlaypanel-close {
  background: rgb(93 185 213 / 76%) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: -10px !important;
  right: -20px !important;
  border: solid 5px #e4cc74 !important;
  color: #e4cc74 !important;
}

.p-overlaypanel:after, .p-overlaypanel:before {
  display: none !important;
}
app-level-game {
  div > img {
    width: 150px;
    height: 150px;
  }

  .levels-container {
    border: solid 10px #e4cc74;
    border-radius: 15%;
    background: rgb(43 195 202 / 58%);

    .level {
      background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/level/level_bg.png');
      position: relative;
    }
  }

  .p-col-4-custom {
    width: 20%;
    min-height: 300px;
  }

  .bordered-custom {
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .title {
    position: absolute;
    top: 0;
    margin-top: 20px;
    font-size: 1.4rem;
    font-family: gameria, sans-serif;
    color: white;
  }

  .sub-level {
    min-width: 150px;
    min-height: 150px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    width: 150px;
    height: 150px;
    position: relative;
  }

  .container-custom {
    width: 170px;
    height: 170px;
  }

  .icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px; /* apply negative top and left margins to truly center the element */
  }

  > div.show-image {
    position: relative;
    float: left;
    margin: 5px;
  }

  > div.show-image:hover button {
    display: flex;
  }

  > div.show-image button {
    position: absolute;
    display: none;
  }

  > div.show-image button.delete {
    top: -5%;
    left: 100%;
    width: 1.5em;
    height: 1.5em;
  }

  .rounded {
    border-radius: 50%;
  }

  .expand {
    width: 100%;
    font-size: 2em;
    text-align: center;
  }

  .play-dashboard {
    min-height: 600px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -10px;
    margin-top: -10px;
    display: none;
  }

  .edit {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 30px;
    margin-top: -10px;
    display: none;
  }

  .delete-container:hover {
    > button.delete {
      display: flex;
      z-index: 1;
    }
    > button.edit {
      display: flex;
      z-index: 1;
    }
  }
}

/**
  * End level-game
 */
/*------------------------------------------------------------*/
/**
  * Start sub-level-game
 */
app-sub-level-game {
  div {
    img.level {
      width: 200px;
      height: 200px;
      border-radius: 5px;
    }

    img.sub-level-image {
      border-radius: 5px;
      width: 154px;
      height: 180px;
    }
  }

  .sub-level-title {
    font-size: 1.2rem;
    font-family: gameria, sans-serif;
    color: white;
    -webkit-text-stroke: 1px black;
  }

  .bordered-custom {
    border: white solid 3px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .sub-level {
    min-width: 150px;
    min-height: 150px;

    img {
      width: 100%;
      height: 100%;
    }

    em.fas.fa-star {
      color: var(--yellow-500);
    }
  }

  .container {
    width: 150px;
    height: 150px;
    position: relative;
  }

  .icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px; /* apply negative top and left margins to truly center the element */
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }

  .delete-container:hover {
    > button.delete {
      display: block;
    }
  }

}

/**
  * End sub-level-game
 */
/*------------------------------------------------------------*/

/**
  * Start interaction-bar
 */
app-interaction-bar {
  i.trophy {
    background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/player/interaction-bar/trophy.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 51px;
    width: 45px;
    &.grey {
      filter: grayscale(100%);
    }
  }
}
/**
  * End interaction-bar
 */
/*------------------------------------------------------------*/

/**
  * Start tool-bar
 */
app-game-tool-bar {
  i.live {
    background-image: url('https://d2hr90nxl1wnyh.cloudfront.net/themes/basic/player/tool-bar/live.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 51px;
    width: 45px;
  }
}
/**
  * End tool-bar
 */
/*------------------------------------------------------------*/

/**
  * Start progress-bar
 */
app-gamy-progress-bar {
  .container {
    text-align: center;
  }

  .github {
    margin: 40px;
  }

  .progress {
    display: inline-block;
    width: 100%;
    height: 50px;
    border-radius: 20px;
    background: #f9f9f9;
    border: solid;
    position: relative;
  }

  .timer {
    font-family: gameria, sans-serif;
    color: white;
    font-size: xxx-large;
    -webkit-text-stroke: 2px black;
    position: absolute;
    top: 0;
    margin-top: -5px;
    left: 50%;
    margin-left: -50px;
  }

  .bar {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    transition: width;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.36, .55, .63, .48);
  }

  .overlap {
    background-color: #137069 !important;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, .15) 10%,
        transparent 10%,
        transparent 20%,
        rgba(255, 255, 255, .15) 20%,
        rgba(255, 255, 255, .15) 30%,
        transparent 30%,
        transparent 40%,
        rgba(255, 255, 255, .15) 40%,
        rgba(255, 255, 255, .15) 50%,
        transparent 50%,
        transparent 60%,
        rgba(255, 255, 255, .15) 60%,
        rgba(255, 255, 255, .15) 70%,
        transparent 70%,
        transparent 80%,
        rgba(255, 255, 255, .15) 80%,
        rgba(255, 255, 255, .15) 90%,
        transparent 90%,
        transparent
    );
  }

  @media screen and (max-width: 500px) {
    .progress {
      width: calc(100vw * 0.7);
      height: 40px;
      margin: 25px;
    }
  }
}
/**
  * End progress-bar
 */
/*------------------------------------------------------------*/
