/* You can add global styles to this file, and also import other style files */
@import '~primeng/resources/primeng.min.css';
@import '~primeicons/primeicons.css';
@import "/src/fonts/font";

@font-face {
  font-family: 'gameria';
  src: url($gameria-font-path) format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

div {
  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .shadow-hover:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .step-container {
    width: 90px;
    height: 90px;
    padding: 10px;
    margin-right: 10px;
    border: rgba(33, 33, 33, 0.39) solid 3px;
    border-radius: 50%;
  }

  .bordered {
    border: #ced4da solid 1px;
    border-radius: 3px;
  }

  .sticky-container-top {
    position: sticky;
    top: 0;
    background: white;
    padding-bottom: 10px !important;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    z-index: 1;
  }
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100vh !important;
}

.full-height {
  height: 100% !important;
}

/**
* Loader CSS
 */

.hidden {
  display: none;
}

.loader-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1111111;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
  transition: 0.5s;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alerts {
  margin: 20px 0;
  color: red;
}

.bi {
  cursor: pointer;
}
